import {
    DesignMethodGroup, DesignStandard as DesignStandardEnum
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';

export class DesignMethodGroupHelper {
    public static isRussianSofaOrSp63(designStandard: DesignStandardEnum, designMethodGroup: DesignMethodGroup) {
        return designStandard == DesignStandardEnum.STO
            &&
            (designMethodGroup == DesignMethodGroup.SP63 || designMethodGroup == DesignMethodGroup.SOFA_Based);
    }

    public static IsLrfdBased(designMethodGroup: DesignMethodGroup | undefined) {
        return designMethodGroup == DesignMethodGroup.LRFD
            || designMethodGroup == DesignMethodGroup.LRFD_318_19;
    }

    public static IsCSAMethod(designStandard?: DesignStandardEnum) {
        switch (designStandard) {
            case DesignStandardEnum.CSA:
                return true;
            default:
                return false;
        }
    }
}
