<div class="utilizations box-section box-section-white" *ngIf="showUtilizations">
    <div class="box-section-header-with-img">
        <div class="box-section-title drag-handle-static utilizaion-title-wrapper"
            [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Region.DragToMove')">
            <div class="image">
                <div class="pe-ui-pe-sprite pe-ui-pe-sprite-anchor-design"></div>
            </div>
            <span class="bar-title" [innerHtml]="translate('Agito.Hilti.Profis3.Utilizations')"></span>
            <div *ngIf="isHandrailPostPositionAvailable()">
                <span class="bar-subtitle" *ngIf="!collapsed && isHandrailPostPositionAvailable()"
                    [innerHtml]="translate(getPostPositionTranslationKey())"></span>
            </div>
            <span class="bar-subtitle"
                *ngIf="!collapsed && !isHandrailPostPositionAvailable() && areLoadCombinationsAvailable" [innerHtml]="loadCombination"></span>
        </div>

        <pe-utilization-bar [collapsed]="collapsed" [utilizationInvalid]="utilizationInvalid" [utilizationValue]="utilizationValue"></pe-utilization-bar>

        <pe-collapse
            [container]="'box-section-content'"
            [collapsed]="collapsed"
            (collapsedChange)="onCollapsedChanged($any($event).detail)">
        </pe-collapse>
    </div>

    <div class="items box-section-content">
        <ng-container *ngFor="let utilization of utilizations(design?.designType?.id); trackBy: trackUtilizationByProperty">
            <div class="utilization"
                *ngIf="getUtilizationFromProperty(utilization.property)?.Decisive != null"
                [class.invalid-value]="getUtilizationFromProperty(utilization.property)?.Decisive?.ValueError"
                [class.invalid-text]="getUtilizationFromProperty(utilization.property)?.Decisive?.TextError">
                <div id="{{id}}-{{utilization.property}}-button" class="utilization-button">
                    <div class="button-content">
                        <div class="text-progress-container">
                            <p class="text" [innerHtml]="translate(utilization.text)"></p>
                            <div class="progress-percent-container"
                                *ngIf="getUtilizationFromProperty(utilization.property)?.Decisive?.Percentage != null">
                                <div class="progress-percent"
                                    [ngStyle]="{ 'width': getUtilizationFromProperty(utilization.property)?.Decisive?.Percentage + '%' }"></div>
                            </div>
                        </div>

                        <div class="number-container" [class.decisive-warning-container]="getUtilizationDetailsFromProperty(utilization.property)">
                            <!-- tension and combination -->
                            <div
                                *ngIf="(utilization.property == 'tension' || utilization.property == 'combination') && getUtilizationOpendFromProperty(utilization.property)"
                                class="details">
                                <div *ngFor="let detail of utilization.details; trackBy: trackUtilizationDetailByProperty">
                                    <div class="details-line"
                                        *ngIf="getUtilizationValueEntityFromDetail(utilization.property, detail.property) != null"
                                        [class.invalid-value]="getUtilizationValueEntityFromDetail(utilization.property, detail.property).ValueError"
                                        [class.invalid-text]="getUtilizationValueEntityFromDetail(utilization.property, detail.property).TextError">
                                        <span class="pe-ui-pe-sprite" [class]="image(detail.image)"></span>
                                        <p class="text" [innerHtml]="translate(detail.text)"></p>
                                        <p class="number notranslate" [innerHtml]="transformValue(getUtilizationValueEntityFromDetail(utilization.property, detail.property).Value)"></p>
                                        <span *ngIf="showWarning(getUtilizationValueEntityFromDetail(utilization.property, detail.property).Value)"
                                            (click)="openAnchorDesignHelp(utilization.property, detail.property)"
                                            class="pe-ui-pe-sprite pe-ui-pe-sprite-notification-alert-small warning"></span>
                                    </div>
                                </div>
                            </div>
                            <!-- shear -->
                            <div *ngIf="utilization.property == 'shear' && getUtilizationOpendFromProperty(utilization.property)" class="details">
                                <div *ngFor="let detail of utilization.details.slice(0, 2); trackBy: trackUtilizationDetailByProperty">
                                    <div class="details-line" *ngIf="getUtilizationValueEntityFromDetail(utilization.property, detail.property) != null"
                                    [class.invalid-value]="getUtilizationValueEntityFromDetail(utilization.property, detail.property).ValueError"
                                    [class.invalid-text]="getUtilizationValueEntityFromDetail(utilization.property, detail.property).TextError">
                                        <span class="pe-ui-pe-sprite" [class]="image(detail.image)"></span>
                                        <p class="text" [innerHtml]="translate(detail.text)"></p>
                                        <p class="number notranslate" [innerHtml]="transformValue(getUtilizationValueEntityFromDetail(utilization.property, detail.property).Value)"></p>
                                        <span *ngIf="showWarning(getUtilizationValueEntityFromDetail(utilization.property, detail.property).Value)"
                                            (click)="openAnchorDesignHelp(utilization.property, detail.property)"
                                            class="pe-ui-pe-sprite pe-ui-pe-sprite-notification-alert-small warning"></span>
                                    </div>
                                </div>

                                <div class="design-right-collapse-container"
                                    *ngIf="isShearConcreteBvcPresent"
                                    [class.opened]="shearConcreteBvcOpend">
                                    <span class="design-right-collapse-line"></span>
                                    <button type="button" class="design-right-collapse-button"
                                        (click)="shearConcreteBvcOpend = !shearConcreteBvcOpend">
                                        <span class="pe-ui-pe-sprite" [ngClass]="shearConcreteBvcOpend ? 'pe-ui-pe-sprite-arrow-up' : 'pe-ui-pe-sprite-arrow-down'"></span>
                                    </button>
                                    <span class="design-right-collapse-line"></span>
                                </div>

                                <div *ngIf="showShearConcreteElements">
                                    <div *ngFor="let detail of utilization.details.slice(2, 6); trackBy: trackUtilizationDetailByProperty">
                                        <div class="details-line"
                                            *ngIf="getUtilizationValueEntityFromDetail(utilization.property, detail.property) != null"
                                            [class.invalid-value]="getUtilizationValueEntityFromDetail(utilization.property, detail.property).ValueError"
                                            [class.invalid-text]="getUtilizationValueEntityFromDetail(utilization.property, detail.property).TextError">
                                            <span class="pe-ui-pe-sprite" [class]="image(detail.image)"></span>
                                            <p class="text" [innerHtml]="translate(detail.text)"></p>
                                            <p class="number notranslate" [innerHtml]="transformValue(getUtilizationValueEntityFromDetail(utilization.property, detail.property).Value)"></p>
                                            <span *ngIf="showWarning(getUtilizationValueEntityFromDetail(utilization.property, detail.property).Value)"
                                                (click)="openAnchorDesignHelp(utilization.property, detail.property)"
                                                class="pe-ui-pe-sprite pe-ui-pe-sprite-notification-alert-small warning"></span>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="isShearLugDoubleFlatBarPresent">
                                    <div *ngFor="let detail of utilization.details.slice(6, 7); trackBy: trackUtilizationDetailByProperty">
                                        <div class="details-line"
                                            *ngIf="getUtilizationValueEntityFromDetail(utilization.property, detail.property) != null"
                                            [class.invalid-value]="getUtilizationValueEntityFromDetail(utilization.property, detail.property).ValueError"
                                            [class.invalid-text]="getUtilizationValueEntityFromDetail(utilization.property, detail.property).TextError">
                                            <span class="pe-ui-pe-sprite" [class]="image(detail.image)"></span>
                                            <p class="text" [innerHtml]="translate(detail.text)"></p>
                                            <p class="number notranslate" [innerHtml]="transformValue(getUtilizationValueEntityFromDetail(utilization.property, detail.property).Value)"></p>
                                            <span *ngIf="showWarning(getUtilizationValueEntityFromDetail(utilization.property, detail.property).Value)"
                                                (click)="openAnchorDesignHelp(utilization.property, detail.property)"
                                                class="pe-ui-pe-sprite pe-ui-pe-sprite-notification-alert-small warning"></span>
                                        </div>
                                    </div>
                                </div>

                                <div *ngFor="let detail of utilization.details.slice(7); trackBy: trackUtilizationDetailByProperty">
                                    <div class="details-line"
                                        *ngIf="getUtilizationValueEntityFromDetail(utilization.property, detail.property) != null"
                                        [class.invalid-value]="getUtilizationValueEntityFromDetail(utilization.property, detail.property).ValueError"
                                        [class.invalid-text]="getUtilizationValueEntityFromDetail(utilization.property, detail.property).TextError">
                                        <span class="pe-ui-pe-sprite" [class]="image(detail.image)"></span>
                                        <p class="text" [innerHtml]="translate(detail.text)"></p>
                                        <p class="number notranslate" [innerHtml]="transformValue(getUtilizationValueEntityFromDetail(utilization.property, detail.property).Value)"></p>
                                        <span *ngIf="showWarning(getUtilizationValueEntityFromDetail(utilization.property, detail.property).Value)"
                                            (click)="openAnchorDesignHelp(utilization.property, detail.property)"
                                            class="pe-ui-pe-sprite pe-ui-pe-sprite-notification-alert-small warning"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="worst-result details-line" *ngIf="!getUtilizationDetailsFromProperty(utilization.property)"
                                [class.invalid-value]="getUtilizationFromProperty(utilization.property)?.Decisive?.ValueError"
                                [class.invalid-text]="getUtilizationFromProperty(utilization.property)?.Decisive?.TextError">
                                <p class="number notranslate decisive-warning"
                                    [innerHtml]="transformValue(getUtilizationFromProperty(utilization.property)?.Decisive?.Value ?? 0)">
                                </p>
                                <span *ngIf="showWarning(getUtilizationFromProperty(utilization.property)?.Decisive?.Value ?? 0, getUtilizationFromProperty(utilization.property))"
                                    (click)="openAnchorDesignHelp(utilization.property)" class="pe-ui-pe-sprite pe-ui-pe-sprite-notification-alert-small warning">
                                </span>
                            </div>

                            <div class="worst-result details-line"
                                *ngIf="getUtilizationDetailsFromProperty(utilization.property) && getUtilizationWorstFromProperty(utilization.property) != null && !getUtilizationOpendFromProperty(utilization.property)"
                                [class.invalid-value]="getUtilizationWorstFromProperty(utilization.property).valueError"
                                [class.invalid-text]="getUtilizationWorstFromProperty(utilization.property).textError">
                                <span class="pe-ui-pe-sprite" [class]="getUtilizationWorstFromProperty(utilization.property).image"></span>
                                <p class="text" [innerHtml]="translate(getUtilizationWorstFromProperty(utilization.property).textKey)"></p>
                                <p class="number notranslate" [innerHtml]="transformValue(getUtilizationWorstFromProperty(utilization.property).value)"></p>
                                <span *ngIf="showWarning(getUtilizationWorstFromProperty(utilization.property).value)"
                                    (click)="openAnchorDesignHelp(utilization.property)"
                                    class="pe-ui-pe-sprite pe-ui-pe-sprite-notification-alert-small warning"></span>
                            </div>
                        </div>

                        <div class="design-right-collapse-container"
                            [class.opened]="getUtilizationOpendFromProperty(utilization.property)"
                            *ngIf="getUtilizationDetailsFromProperty(utilization.property) && getUtilizationLengthFromProperty(utilization.property) > 1">

                            <span class="design-right-collapse-line"></span>

                            <button type="button" class="design-right-collapse-button"
                                (click)="changeUtilizationPropertyOpend(utilization.property)">
                                <span class="pe-ui-pe-sprite" [ngClass]="getUtilizationOpendFromProperty(utilization.property) ? 'pe-ui-pe-sprite-arrow-up' : 'pe-ui-pe-sprite-arrow-down'"></span>
                            </button>

                            <span class="design-right-collapse-line"></span>
                        </div>
                    </div>
                </div>
            </div>
         </ng-container>
    </div>
</div>
